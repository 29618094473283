import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { EnforceUserSignInFlexModuleResult, EnforceUserSignInProps } from "./typings";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSSecondaryButton } from "@egds/react-core/button";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { useLocalization } from "@shared-ui/localization-context";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import {
  Action,
  FlexTrackingInfo,
  sendImmediateClickstreamTrackEvent,
} from "components/utility/analytics/FlexAnalyticsUtils";

const ButtonTracked = FlexClickTracker(EGDSSecondaryButton);
const signInModuleName = "Partner.Account.SignIn";
const flexTracking: FlexTrackingInfo = {
  moduleName: signInModuleName,
  rfrr: "Presented",
  action: Action.IMPRESSION,
};

export const EnforceUserSignIn = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: EnforceUserSignInProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as EnforceUserSignInFlexModuleResult | null;
    const { formatText } = useLocalization();

    if (!model) {
      return null;
    }
    const { signInURL, createAccountURL, title, subheading } = model;
    const signInLabel = formatText("rewardsBanner.signIn");
    const joinNowLabel = formatText("rewardsBanner.joinNow");
    const track = useClickTracker();

    React.useEffect(() => {
      sendImmediateClickstreamTrackEvent(flexTracking, track);
    }, [track]);

    return (
      <EGDSCard border padded className="sign-in-card">
        <EGDSLayoutFlex direction="column" space="three">
          <EGDSLayoutFlexItem alignSelf="center">
            <EGDSIcon name="account_circle" size={EGDSIconSize.LARGE} theme="default" />
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem alignSelf="center">
            <EGDSHeading align="center" size={6} tag="h1" data-testid="heading">
              {title}
            </EGDSHeading>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem alignSelf="center">
            <EGDSText align="center" weight="regular" data-testid="sub-heading">
              {subheading}
            </EGDSText>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem>
            <EGDSLayoutFlex space="two" wrap="wrap">
              <EGDSLayoutFlexItem grow={1} minWidth="sixteen">
                <ButtonTracked href={signInURL} data-testid="sign-in-button" moduleName={signInModuleName} rfrr="Login">
                  {signInLabel}
                </ButtonTracked>
              </EGDSLayoutFlexItem>
              {createAccountURL && (
                <EGDSLayoutFlexItem grow={1} minWidth="sixteen">
                  <ButtonTracked
                    href={createAccountURL}
                    data-testid="create-account-button"
                    moduleName={signInModuleName}
                    rfrr="Join"
                  >
                    {joinNowLabel}
                  </ButtonTracked>
                </EGDSLayoutFlexItem>
              )}
            </EGDSLayoutFlex>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    );
  })
);

export default EnforceUserSignIn;
